.pageLoading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-bg-page);
  z-index: 700;
  cursor: wait;
}

.pageLoading > * {
  height: 120px;
  width: auto;
  margin-top: calc(50dvh - 60px);
  animation: loadingIconAppearance 0.35s ease, loadingIconColorChange 0.35s infinite alternate;
}

@keyframes loadingIconAppearance {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadingIconColorChange {
  0% {
    fill: var(--gray);
  }
  100% {
    fill: white;
  }
}
