.gallery {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.gallery-scrollButton {
  min-width: var(--size-button-gallery);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery > .gallery-scrollButton:last-child {
  margin-left: auto;
}

.gallery-scrollButton > svg {
  font-size: var(--size-button-gallery);
  cursor: pointer;
}

.gallery-scrollButton > svg:active {
  font-size: calc(var(--size-button-gallery) - 3px);
}

.gallery-list {
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 10px;
}

/* Media query for touchscreens */
@media (hover: none) {
  .gallery-list {
    padding: 0;
  }
}

.gallery-list > * {
  display: inline-block;
  margin: 0 var(--margin-md);
}

.gallery-img {
  margin: 0 var(--margin-xs);
}
